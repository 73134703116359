<template>
  <div class="dashboard-overview">
    <!-- Key Metrics Section -->
    <div class="key-metrics mb-12">
      <h2 class="text-2xl font-semibold mb-6">Key Metrics</h2>
      
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <!-- KTWC Card -->
        <div class="metric-card flex flex-col p-6 rounded-lg bg-white shadow-md border border-gray-100">
          <h3 class="text-sm font-medium text-gray-500 uppercase mb-4">KTWC Stats</h3>
          <div class="grid grid-cols-2 gap-4 mb-2">
            <div class="flex flex-col items-center">
              <span class="text-xs uppercase text-gray-500">Knock</span>
              <span class="text-xl font-bold">{{ formatNumber(metrics.knock) }}</span>
            </div>
            <div class="flex flex-col items-center">
              <span class="text-xs uppercase text-gray-500">Talk</span>
              <span class="text-xl font-bold">{{ formatNumber(metrics.talk) }}</span>
            </div>
            <div class="flex flex-col items-center">
              <span class="text-xs uppercase text-gray-500">Walk</span>
              <span class="text-xl font-bold">{{ formatNumber(metrics.walk) }}</span>
            </div>
            <div class="flex flex-col items-center">
              <span class="text-xs uppercase text-gray-500">Ctgy</span>
              <span class="text-xl font-bold">{{ formatNumber(metrics.contingency) }}</span>
            </div>
          </div>
          <div class="mt-auto pt-2">
            <div class="text-xs text-gray-500 flex justify-between">
              <span>Conversion: </span>
              <span class="font-semibold">{{ calculateConversion(metrics.talk, metrics.walk) }}%</span>
            </div>
          </div>
        </div>
        
        <!-- Revenue Card -->
        <div class="metric-card flex flex-col p-6 rounded-lg bg-white shadow-md border border-gray-100">
          <h3 class="text-sm font-medium text-gray-500 uppercase mb-2">Revenue</h3>
          <div class="text-3xl font-bold mb-2">{{ formatCurrency(metrics.revenue) }}</div>
          <div class="text-sm text-gray-600 mb-2">MTD: {{ formatCurrency(metrics.mtd) }}</div>
          <div class="mt-auto">
            <div class="text-xs text-gray-500 flex justify-between">
              <span>Per Contract: </span>
              <span class="font-semibold">{{ formatCurrency(metrics.revenuePerContract) }}</span>
            </div>
          </div>
        </div>
        
        <!-- Contracts Card -->
        <div class="metric-card flex flex-col p-6 rounded-lg bg-white shadow-md border border-gray-100">
          <h3 class="text-sm font-medium text-gray-500 uppercase mb-2">Contracts</h3>
          <div class="text-3xl font-bold mb-2">{{ formatNumber(metrics.contracts) }}</div>
          <div class="text-sm text-gray-600 mb-2">Leads: {{ formatNumber(metrics.salesOpportunities) }}</div>
          <div class="mt-auto">
            <div class="text-xs text-gray-500 flex justify-between">
              <span>Conversion Rate: </span>
              <span>{{ calculateConversion(metrics.salesOpportunities, metrics.contracts) }}%</span>
            </div>
          </div>
        </div>
        
        <!-- Other Metrics Card -->
        <div class="metric-card flex flex-col p-6 rounded-lg bg-white shadow-md border border-gray-100">
          <h3 class="text-sm font-medium text-gray-500 uppercase mb-2">Other Metrics</h3>
          <div class="space-y-2">
            <div class="flex justify-between">
              <span class="text-sm">Soft Sets:</span>
              <span class="text-sm font-medium">{{ formatNumber(metrics.softSets) }}</span>
            </div>
            <div class="flex justify-between">
              <span class="text-sm">Sales Opportunities:</span>
              <span class="text-sm font-medium">{{ formatNumber(metrics.salesOpportunities) }}</span>
            </div>
            <div class="flex justify-between">
              <span class="text-sm">Installations Started:</span>
              <span class="text-sm font-medium">{{ formatNumber(metrics.installationsStarted) }}</span>
            </div>
            <div class="flex justify-between">
              <span class="text-sm">Installations Revenue:</span>
              <span class="text-sm font-medium">{{ formatCurrency(metrics.installationsRevenue) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Charts Section -->
    <div class="charts-section grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12">
      <!-- Trend Chart -->
      <div class="chart-card p-6 rounded-lg bg-white shadow-md border border-gray-100">
        <h3 class="text-lg font-medium mb-4">Performance Trends</h3>
        <div class="h-80">
          <ReportChart type="company" />
        </div>
      </div>
      
      <!-- Distribution Chart -->
      <div class="chart-card p-6 rounded-lg bg-white shadow-md border border-gray-100">
        <h3 class="text-lg font-medium mb-4">Performance Distribution</h3>
        <div class="h-80">
          <PieChart :reports="data" type="branch" />
        </div>
      </div>
    </div>
    
    <!-- Top Performers Section -->
    <div class="top-performers mb-12">
      <h2 class="text-2xl font-semibold mb-6">Top Performers</h2>
      
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <!-- Top Branches -->
        <div class="p-6 rounded-lg bg-white shadow-md border border-gray-100">
          <h3 class="text-lg font-medium mb-4">Top Branches</h3>
          <div class="overflow-x-auto">
            <table class="w-full min-w-full table-auto text-sm">
              <thead>
                <tr class="bg-gray-50 text-left">
                  <th class="py-2 px-4 font-medium text-gray-600">Branch</th>
                  <th class="py-2 px-4 font-medium text-gray-600">Revenue</th>
                  <th class="py-2 px-4 font-medium text-gray-600">Contracts</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="branch in topBranches" :key="branch.id" class="border-b">
                  <td class="py-2 px-4">{{ branch.name }}</td>
                  <td class="py-2 px-4">{{ formatCurrency(branch.revenue) }}</td>
                  <td class="py-2 px-4">{{ branch.contracts }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
        <!-- Top Reps -->
        <div class="p-6 rounded-lg bg-white shadow-md border border-gray-100">
          <h3 class="text-lg font-medium mb-4">Top Team Members</h3>
          <div class="overflow-x-auto">
            <table class="w-full min-w-full table-auto text-sm">
              <thead>
                <tr class="bg-gray-50 text-left">
                  <th class="py-2 px-4 font-medium text-gray-600">Member</th>
                  <th class="py-2 px-4 font-medium text-gray-600">Revenue</th>
                  <th class="py-2 px-4 font-medium text-gray-600">Contracts</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="rep in topReps" :key="rep.id" class="border-b">
                  <td class="py-2 px-4">{{ rep.name }}</td>
                  <td class="py-2 px-4">{{ formatCurrency(rep.revenue) }}</td>
                  <td class="py-2 px-4">{{ rep.contracts }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Recent Reports Section -->
    <div class="recent-reports">
      <div class="flex justify-between items-center mb-6">
        <h2 class="text-2xl font-semibold">Recent Reports</h2>
        <router-link 
          :to="'/branchReports/view'" 
          class="text-blue-600 hover:text-blue-800 text-sm font-medium"
        >
          View All Reports →
        </router-link>
      </div>
      
      <div class="grid grid-cols-1 gap-4">
        <BranchReport 
          v-for="report in recentBranchReports" 
          :key="report.id" 
          :report="report" 
          class="transition-all duration-200 hover:shadow-lg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ReportChart from '../layout/reportChart.vue';
import PieChart from '../layout/pieChart.vue';

export default {
  name: 'DashboardOverview',
  components: {
    ReportChart,
    PieChart
  },
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
    dateRange: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    // Calculate aggregated metrics from all reports
    metrics() {
      if (!this.data) return {};
      
      // Initialize metrics
      const metrics = {
        knock: 0,
        talk: 0,
        walk: 0,
        contingency: 0,
        revenue: 0,
        mtd: 0,
        revenuePerContract: 0,
        contracts: 0,
        salesOpportunities: 0,
        softSets: 0,
        installationsStarted: 0,
        installationsRevenue: 0
      };

      // Aggregate metrics from both branch and rep reports
      this.data.forEach(report => {
        // Add company-level metrics
        metrics.revenue += report.revenue || 0;
        metrics.contracts += report.contracts || 0;
        metrics.salesOpportunities += report.salesOpportunities || 0;
        metrics.softSets += report.softSets || 0;
        metrics.installationsStarted += report.installationsStarted || 0;
        metrics.installationsRevenue += report.startedInstallationsRevenue || 0;

        // Add branch-level metrics
        (report.branchReports || []).forEach(br => {
          metrics.knock += br.knock || 0;
          metrics.talk += br.talk || 0;
          metrics.walk += br.walk || 0;
          metrics.contingency += br.contingency || 0;
        });

        // Add rep-level metrics
        (report.repReports || []).forEach(rr => {
          // Some metrics might also be in rep reports
          metrics.revenue += rr.revenue || 0;
          metrics.contracts += rr.contracts || 0;
          metrics.salesOpportunities += rr.salesOpportunities || 0;
          metrics.installationsRevenue += rr.startedInstallationsRevenue || 0;
        });
      });
      
      // Calculate month-to-date revenue
      const today = new Date();
      const mtdReports = this.data.filter(report => {
        const reportDate = new Date(report.created);
        return reportDate.getMonth() === today.getMonth() && 
               reportDate.getFullYear() === today.getFullYear();
      });
      
      metrics.mtd = mtdReports.reduce((sum, report) => {
        const reportRevenue = report.revenue || 0;
        const repRevenueSum = (report.repReports || []).reduce((sum, rr) => sum + (rr.revenue || 0), 0);
        return sum + reportRevenue + repRevenueSum;
      }, 0);
      
      // Calculate revenue per contract
      metrics.revenuePerContract = metrics.contracts > 0 ? 
        metrics.revenue / metrics.contracts : 0;
      
      return metrics;
    },
    
    // Get top 5 branches by revenue
    topBranches() {
      if (!this.data) return [];

      // Get all branch reports
      const branchReports = this.data.flatMap(report => report.branchReports || []);
      
      // Group reports by branch
      const branchStats = new Map();
      branchReports.forEach(report => {
        const branchId = report.branch.id;
        if (!branchStats.has(branchId)) {
          branchStats.set(branchId, {
            id: branchId,
            name: report.branch.title,
            revenue: 0,
            contracts: 0
          });
        }
        const stats = branchStats.get(branchId);
        stats.revenue += report.revenue || 0;
        stats.contracts += report.contracts || 0;
      });

      // Sort branches by revenue and get top 5
      return Array.from(branchStats.values())
        .sort((a, b) => b.revenue - a.revenue)
        .slice(0, 5);
    },
    
    // Get top 5 reps by revenue
    topReps() {
      if (!this.data) return [];

      // Get all rep reports with their parent report's created date
      const repReports = this.data.flatMap(report => 
        (report.repReports || []).map(rr => ({
          ...rr,
          created: report.created,
          rep: Array.isArray(rr.rep) ? rr.rep[0] : rr.rep // Handle both array and object formats
        }))
      );
      
      // Group reports by rep
      const repStats = new Map();
      repReports.forEach(report => {
        if (!report.rep) return;
        
        const repId = report.rep.id;
        if (!repStats.has(repId)) {
          repStats.set(repId, {
            id: repId,
            name: report.rep.title || 'Unknown Rep',
            revenue: 0,
            contracts: 0
          });
        }
        const stats = repStats.get(repId);
        stats.revenue += report.revenue || 0;
        stats.contracts += report.contracts || 0;
      });

      // Sort reps by revenue and get top 5
      return Array.from(repStats.values())
        .sort((a, b) => b.revenue - a.revenue)
        .filter(rep => rep.revenue > 0) // Only show reps with revenue
        .slice(0, 5);
    },
    
    // Get 3 most recent branch reports
    recentBranchReports() {
      if (!this.data) return [];

      // Get all branch reports with their dates
      const branchReports = this.data.flatMap(report => 
        (report.branchReports || []).map(br => ({
          ...br,
          created: report.created
        }))
      );

      // Sort by date and get 3 most recent
      return branchReports
        .sort((a, b) => new Date(b.created) - new Date(a.created))
        .slice(0, 3);
    }
  },
  methods: {
    calculateConversion(value1, value2) {
      if (!value1 || !value2) return 0;
      return Math.round((value2 / value1) * 100);
    },
    
    formatCurrency(value) {
      if (!value) return '$0';
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(value);
    },
    
    formatNumber(value) {
      if (!value) return '0';
      return new Intl.NumberFormat('en-US').format(value);
    }
  }
};
</script>

<style scoped>
.metric-card {
  height: 100%;
  min-height: 180px;
  transition: all 0.2s ease-in-out;
}

.metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.chart-card, .p-6 {
  transition: all 0.2s ease-in-out;
}

.chart-card:hover, .p-6:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
</style>
