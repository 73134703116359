<template>
    <div class="reporting-dashboard">
        <!-- Header Section -->
        <div class="header mb-8">
            <h1 class="text-3xl font-bold text-center mb-4">Reporting Dashboard</h1>
            <p class="text-gray-600 text-center mb-6">
                Comprehensive metrics and analytics for your team and branches
            </p>

            <!-- Date Range Selector -->
            <div class="date-range flex flex-wrap justify-center gap-4 mb-6">
                <div class="flex items-center space-x-2">
                    <span class="text-sm font-medium">Date Range:</span>
                    <input type="date" v-model="filters.rangeStart" @change="refreshData"
                        class="border border-gray-300 rounded px-3 py-2 text-sm" />
                    <span>to</span>
                    <input type="date" v-model="filters.rangeEnd" @change="refreshData"
                        class="border border-gray-300 rounded px-3 py-2 text-sm" />
                </div>

                <div class="flex items-center space-x-2">
                    <button @click="setDateRange('week')"
                        class="px-3 py-2 text-sm border rounded-lg transition-colors duration-200" :class="{
                            'border-blue-500 bg-blue-50 text-blue-700':
                                activeDatePreset === 'week',
                            'border-gray-300': activeDatePreset !== 'week',
                        }">
                        Past Week
                    </button>
                    <button @click="setDateRange('month')"
                        class="px-3 py-2 text-sm border rounded-lg transition-colors duration-200" :class="{
                            'border-blue-500 bg-blue-50 text-blue-700':
                                activeDatePreset === 'month',
                            'border-gray-300': activeDatePreset !== 'month',
                        }">
                        Past Month
                    </button>
                    <button @click="setDateRange('quarter')"
                        class="px-3 py-2 text-sm border rounded-lg transition-colors duration-200" :class="{
                            'border-blue-500 bg-blue-50 text-blue-700':
                                activeDatePreset === 'quarter',
                            'border-gray-300': activeDatePreset !== 'quarter',
                        }">
                        Past Quarter
                    </button>
                </div>
            </div>

            <!-- Filter Controls -->
            <div class="filters flex flex-wrap justify-center gap-4">
                <div v-if="admin || branches?.length > 1" class="branch-filter min-w-[200px]">
                    <MultiSelect v-model:modelSelectedIds="filters.branch" collection="branches" :multiple="true"
                        :create="false" :small="true" placeholder="Filter by Branch"
                        @update:modelSelectedIds="refreshData" />
                </div>

                <div class="view-toggle flex items-center space-x-2">
                    <button @click="activeView = 'overview'"
                        class="px-3 py-2 text-sm border rounded-lg transition-colors duration-200" :class="{
                            'border-blue-500 bg-blue-50 text-blue-700':
                                activeView === 'overview',
                            'border-gray-300': activeView !== 'overview',
                        }">
                        Overview
                    </button>
                    <button @click="activeView = 'branches'"
                        class="px-3 py-2 text-sm border rounded-lg transition-colors duration-200" :class="{
                            'border-blue-500 bg-blue-50 text-blue-700':
                                activeView === 'branches',
                            'border-gray-300': activeView !== 'branches',
                        }">
                        Branches
                    </button>
                    <button @click="activeView = 'teams'"
                        class="px-3 py-2 text-sm border rounded-lg transition-colors duration-200" :class="{
                            'border-blue-500 bg-blue-50 text-blue-700':
                                activeView === 'teams',
                            'border-gray-300': activeView !== 'teams',
                        }">
                        Teams
                    </button>
                    <button @click="activeView = 'export'"
                        class="px-3 py-2 text-sm border rounded-lg transition-colors duration-200" :class="{
                            'border-blue-500 bg-blue-50 text-blue-700':
                                activeView === 'export',
                            'border-gray-300': activeView !== 'export',
                        }">
                        Export
                    </button>
                </div>
            </div>
        </div>

        <!-- Main Content Area -->
        <div class="content-area">
            <!-- Loading Indicator -->
            <div v-if="loading" class="loading-container flex items-center justify-center min-h-[400px]">
                <Spinner :small="false" class="w-16 h-16" />
            </div>

            <!-- Dashboard Content -->
            <div v-else class="dashboard-content">
                <!-- Overview View -->
                <DashboardOverview v-if="activeView === 'overview'" :data="enrichedReports"
                    :dateRange="{ start: filters.rangeStart, end: filters.rangeEnd }" />

                <!-- Branches View -->
                <BranchesReport v-else-if="activeView === 'branches'" :branchData="enrichedReports"
                    :dateRange="{ start: filters.rangeStart, end: filters.rangeEnd }" />

                <!-- Teams View -->
                <TeamsReport v-else-if="activeView === 'teams'" :companyReports="enrichedReports"
                    :dateRange="{ start: filters.rangeStart, end: filters.rangeEnd }" />

                <!-- Export View -->
                <ExportReport v-else-if="activeView === 'export'"
                    :dateRange="{ start: filters.rangeStart, end: filters.rangeEnd }"
                    :selectedBranches="filters.branch" />
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import MultiSelect from '../../helpers/multiSelect.vue'
import DashboardOverview from './dashboard-overview.vue'
import BranchesReport from './branches-report.vue'
import TeamsReport from './teams-report.vue'
import ExportReport from './export-report.vue'
import Mango from '../../helpers/mango'

export default {
    name: 'ReportingDashboard',
    components: {
        MultiSelect,
        DashboardOverview,
        BranchesReport,
        TeamsReport,
        ExportReport,
    },
    inject: ['store', 'axios'],
    data() {
        return {
            companyReports: [],
            branchReports: [],
            repReports: [],
            branches: [],
            reps: [],
            loading: false,
            activeView: 'overview',
            activeDatePreset: 'month',
            filters: {
                rangeStart: dayjs().startOf('month').format('YYYY-MM-DD'),
                rangeEnd: dayjs().format('YYYY-MM-DD'),
                branch: []
            }
        }
    },
    computed: {
        admin() {
            return this.store?.user?.roles?.includes('admin')
        },
        enrichedReports() {
            // Create lookup maps for efficient joining
            const branchMap = new Map(this.branches.map(b => [b.id, b]));
            const repMap = new Map(this.reps.map(r => [r.id, r]));
            const branchReportMap = new Map(this.branchReports.map(br => [br.id, br]));
            const repReportMap = new Map(this.repReports.map(rr => [rr.id, rr]));

            return this.companyReports.map(report => ({
                ...report,
                branchReports: (report.branchReports || []).map(id => {
                    const br = branchReportMap.get(id);
                    return br ? {
                        ...br,
                        branch: branchMap.get(br.branch[0])
                    } : null;
                }).filter(Boolean),
                repReports: (report.repReports || []).map(id => {
                    const rr = repReportMap.get(id);
                    return rr ? {
                        ...rr,
                        rep: repMap.get(rr.rep[0]),
                        branch: branchMap.get(rr.branch[0])
                    } : null;
                }).filter(Boolean)
            }));
        }
    },
    created() {
        // Initialize with user's branches if they have any
        if (this.store?.user?.branches?.length) {
            this.filters.branch = this.store.user.branches.map((b) => b.id)
        }
        this.refreshData()
    },
    methods: {
        async refreshData() {
            this.loading = true

            try {
                // Fetch data for all views
                await this.fetchOverviewData()
            } catch (error) {
                console.error('Error fetching report data:', error)
            } finally {
                this.loading = false
            }
        },

        async fetchOverviewData() {
            try {
                const params = {
                    startDate: this.filters.rangeStart,
                    endDate: this.filters.rangeEnd
                };

                if (this.filters.branch.length && !this.admin) {
                    params.branches = this.filters.branch;
                }

                const response = await this.axios.get(`${this.store.api}/controllers/reportingData`, { params });
                if (response?.data) {
                    // Store separate entities
                    this.companyReports = response.data.companyReports;
                    this.branchReports = response.data.branchReports;
                    this.repReports = response.data.repReports;
                    this.branches = response.data.branches;
                    this.reps = response.data.reps;
                }
            } catch (error) {
                console.error('Error fetching overview data:', error);
            }
        },

        setDateRange(preset) {
            this.activeDatePreset = preset

            switch (preset) {
                case 'week':
                    this.filters.rangeStart = dayjs()
                        .subtract(7, 'days')
                        .format('YYYY-MM-DD')
                    this.filters.rangeEnd = dayjs().format('YYYY-MM-DD')
                    break
                case 'month':
                    this.filters.rangeStart = dayjs()
                        .startOf('month')
                        .format('YYYY-MM-DD')
                    this.filters.rangeEnd = dayjs().format('YYYY-MM-DD')
                    break
                case 'quarter':
                    this.filters.rangeStart = dayjs()
                        .subtract(90, 'days')
                        .format('YYYY-MM-DD')
                    this.filters.rangeEnd = dayjs().format('YYYY-MM-DD')
                    break
            }

            this.refreshData()
        },
    },
}
</script>

<style scoped>
.reporting-dashboard {
    @apply w-full max-w-7xl mx-auto bg-white p-6 rounded-lg shadow-lg;
}

.header {
    @apply border-b border-gray-200 pb-6;
}

.dashboard-content {
    @apply mt-8;
}

/* Responsive adjustments */
@media (max-width: 768px) {

    .date-range,
    .filters {
        @apply flex-col items-center;
    }

    .view-toggle {
        @apply mt-4 w-full justify-center;
    }
}
</style>
