<template>
  <div class="reporting-page">
    <ReportingDashboard />
  </div>
</template>

<script>
import ReportingDashboard from '../components/reporting/reporting.vue';

export default {
  name: 'ReportingPage',
  components: {
    ReportingDashboard
  }
};
</script>

<style scoped>
.reporting-page {
  @apply w-full;
}
</style>
