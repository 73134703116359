<template>

    <Spinner v-if="!opportunities.length" />
    <!-- <Draggable v-else
        v-model="dashboardSettings.widgets" item-key="name"
        :disabled="store.touchDevice"
        class="w-full grid grid-flow-row-dense gap-8 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 p-4 md:p-8 "
    >
        <template #item="{element}">
            <Component :is="element.component" :opportunities="activeOpportunities" :finishedLoading="finishedLoading" :startRange="startRange" />
        </template>
    </Draggable> -->

    <div v-else class="w-full h-[calc(100vh-3.5rem)] flex">
        <repMap class="w-full flex-grow" :opportunities="opportunities" :startRange="startRange" />
    </div>

</template>

<script>
// Function for setting cookies
let setCookie = function (cname, cvalue) {
    var d = new Date();
    d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

import repMap from '../../widgets/repMap.vue'
import latestOpportunities from '../../widgets/latestOpportunities.vue'
import topPerformers from '../../widgets/topPerformers.vue'

import Draggable from 'vuedraggable'
import dayjs from 'dayjs'
import Mango from '../../helpers/mango'
import latestCutoff from '../../helpers/latestCutoff'

export default {
    inject: ['store','axios'],
    components: {
        Draggable,

        latestOpportunities,
        topPerformers,
        repMap,
    },
    data() {
        return {
            lastChecked: null,
            page: 0,
            lastMonth: dayjs().subtract(1, 'month').toDate(),
            today: dayjs(dayjs().format('YYYY-MM-DD')),
            opportunities: [],
            fetchOpportunitiesInterval: null,
            fields: [
                'salesReps',
                'address.formatted',
                'address.coordinates',
                'address.city',
                'address.state',
                'ownerInfo.firstName',
                'ownerInfo.lastName',
                'selfie',
                'title',
                'status',
                'revenue',
                'created',
                'updated',
                'authorId',
                'shotgun',
                'branch',
                'ktwc',
                'timestamps',
                'insuranceAccepted',
                'contractSigned',
                'inspection',
                'discussion',
                'revenueReported',
            ],
            dashboardSettings: {
                widgets: [
                    // {name: 'Latest Opportunities', component: 'latestOpportunities'},
                    {name: 'Latest Opportunities', component: 'repMap'},
                    {name: 'Top Performers', component: 'topPerformers'},
                ]
            }
        }
    },
    created() {
        let savedSettings = window.localStorage.getItem('dashboardSettings')
        if (savedSettings) {
            savedSettings = JSON.parse(savedSettings)
            let missingWidgets = this.dashboardSettings.widgets.filter(w => !savedSettings.widgets.find(s => s.name == w.name))

            this.dashboardSettings = savedSettings
            this.dashboardSettings.widgets.push(...missingWidgets)

            // Remove missing widgets
            this.dashboardSettings.widgets = this.dashboardSettings.widgets.filter(w => savedSettings.widgets.find(s => s.name == w.name))
        }
    },
    mounted() {
        this.loadMapData()
        // let intervalMinutes = 5
        let intervalMinutes = 0.1
        if (process.env.NODE_ENV == 'production') intervalMinutes = 0.5
        this.fetchOpportunitiesInterval = setInterval(() => {
            this.loadMapData(true)
        }, 1000 * 60 * intervalMinutes)
    },
    beforeRouteLeave() {
        clearInterval(this.fetchOpportunitiesInterval)
    },
    watch: {
        dashboardSettings: {
            handler() {
                window.localStorage.setItem('dashboardSettings', JSON.stringify(this.dashboardSettings))
            },
            deep: true
        }
    },
    computed: {
        startRange() {
            return this.latestCutoff()
            if (!this.store.isDev) return this.latestCutoff()
            return this.latestCutoff(new Date('4/1/2025'))
        },
    },
    methods: {
        latestCutoff,
        inRange(l, param) {
            param = l?.timestamps?.[param] || l?.[param]
            return new Date(param) > new Date(this.startRange)
        },
        async loadMapData(checkForUpdates = false) {
            // return console.log(this.startRange)
            const params = {
                startDate: this.startRange,
                ...(checkForUpdates && this.lastChecked && { lastChecked: this.lastChecked })
            };

            try {
                const response = await this.axios.get(`${this.store.api}/controllers/repMapData?${new URLSearchParams(params)}`);
                const data = await response.data;

                if (checkForUpdates && this.opportunities) {
                    // Update existing opportunities and add new ones
                    const updatedOpportunities = [...this.opportunities];
                    data.opportunities.forEach(newOpp => {
                        const index = updatedOpportunities.findIndex(o => o._id === newOpp._id);
                        if (index !== -1) {
                            updatedOpportunities[index] = newOpp;
                        } else {
                            updatedOpportunities.push(newOpp);
                        }
                    });
                    this.opportunities = updatedOpportunities;
                } else {
                    this.opportunities = data.opportunities;
                }

                this.lastChecked = new Date().toISOString();
                this.$emit('update:totals', data.totals);
            } catch (error) {
                console.error('Error loading map data:', error);
            }
        }
    }
}
</script>
