<template>
    <div>
        <Hero title="Export Opportunities" class="" />
        <div class="py-8 flex flex-col items-center justify-center space-y-8 border-y bg-white shadow-card">

            <div class="space-y-4 w-full px-8 border-b-[0.5px] pb-8 w-full max-w-6xl">
                <div class="flex justify-between items-center">
                    <div>
                        <div class="text-2xs uppercase tracking-widest font-semibold opacity-60 mb-2">Entry Date Range</div>
                        <div class="text-xs flex space-x-4 items-center">
                            <input @click="block = null" type="date" v-model="filters.rangeStart" class="border border-gray-300 rounded px-2 py-1" />
                            <span class="mx-2">to</span>
                            <input @click="block = null" type="date" v-model="filters.rangeEnd" class="border border-gray-300 rounded px-2 py-1" />

                            <button @click="block = 'week'" :class="{'border-blue-500': block == 'week'}" class="px-2 py-1 text-xs border rounded-lg bg-white">Past&nbsp;Week</button>
                            <button @click="block = 'month'" :class="{'border-blue-500': block == 'month'}" class="px-2 py-1 text-xs border rounded-lg bg-white">Past&nbsp;Month</button>
                            <button @click="block = 'year'" :class="{'border-blue-500': block == 'year'}" class="px-2 py-1 text-xs border rounded-lg bg-white">Past&nbsp;Year</button>

                        </div>
                    </div>
                    <div class="">
                        <div class="text-2xs uppercase tracking-widest font-semibold opacity-60 mb-1">Calendar Dates</div>
                        <Toggle v-model="calendarDates" :small="true" background="bg-gray-200 dark:bg-gray-700" />
                    </div>
                </div>

                <div class="flex gap-4 w-full overflow-x-scroll">
                    <div v-for="month in calendar?.months" :key="month.month" class="shrink-0">
                        <div class="text-2xs mb-1">{{ month.month }}</div>
                        <div class="grid grid-cols-7 border-[0.5px]">
                            <div
                                v-for="day in month.days" :key="day"
                                class="flex items-center justify-center p-1 text-2xs border-[0.5px]"
                                :class="{
                                    'bg-gray-100': !day,
                                    'bg-sky-100': (dayjs(day) >= dayjs(filters.rangeStart)) && (dayjs(day) <= dayjs(filters.rangeEnd))
                                }
                            ">
                                {{ day ? dayjs(day).format('D') : '' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex px-8 w-full filters space-x-4 justify-between w-full max-w-6xl">

                <div class="space-y-4 w-full max-w-xs">
                    <div v-if="admin || branches?.length > 1" class="overflow-visible">
                        <div class="text-2xs uppercase tracking-widest font-semibold opacity-60 mb-2">Branch</div>
                        <div class="text-xs mb-2 opacity-40">Leave empty to include all branches</div>
                        <MultiSelect
                            v-model:modelSelectedIds="filters.branch"
                            v-model:modelSelectedEntries="branchEntries"
                            :algoliaFilters="branchSearchFilters"
                            collection="branches"
                            :multiple="true"
                            :create="false"
                            :small="true"
                        />
                    </div>
                </div>

                <div class="space-y-4 w-full max-w-xs">
                    <!-- <div class="">
                        <div class="text-2xs uppercase tracking-widest font-semibold opacity-60 mb-2">Entry Date Range</div>
                        <div class="text-xs flex space-x-4 items-center">
                            <input type="date" v-model="filters.rangeStart" class="border border-gray-300 rounded px-2 py-1" />
                            <span class="mx-2">to</span>
                            <input type="date" v-model="filters.rangeEnd" class="border border-gray-300 rounded px-2 py-1" />
                        </div>
                    </div> -->

                    <div class="">
                        <div class="text-2xs uppercase tracking-widest font-semibold opacity-60 mb-2">Revenue</div>
                        <div class="flex items-center space-x-4">
                            <input v-model.number="filters.revenueMin" placeholder="Min" />
                            <input v-model.number="filters.revenueMax" placeholder="Max" />
                        </div>
                    </div>
                </div>

                <div class="grid grid-cols-2 gap-4">
                    <div class="w-full md:w-1/2">
                        <div class="text-2xs uppercase tracking-widest font-semibold opacity-60 mb-2">Contract&nbsp;Signed</div>
                        <Toggle v-model="filters.contractSigned" :small="false" background="bg-gray-200 dark:bg-gray-700" />
                    </div>

                    <div class="w-full md:w-1/2">
                        <div class="text-2xs uppercase tracking-widest font-semibold opacity-60 mb-2">Insurance&nbsp;Accepted</div>
                        <Toggle v-model="filters.insuranceAccepted" :small="false" background="bg-gray-200 dark:bg-gray-700" />
                    </div>

                    <div class="w-full md:w-1/2">
                        <div class="text-2xs uppercase tracking-widest font-semibold opacity-60 mb-2">Retail</div>
                        <Toggle v-model="filters.retail" :small="false" background="bg-gray-200 dark:bg-gray-700" />
                    </div>

                    <div class="w-full md:w-1/2">
                        <div class="text-2xs uppercase tracking-widest font-semibold opacity-60 mb-2">Installation&nbsp;Started</div>
                        <Toggle v-model="filters.installationStarted" :small="false" background="bg-gray-200 dark:bg-gray-700" />
                    </div>
                </div>

            </div>

            <div v-if="daysInRange > 100 && (!filters.branch?.length || filters.branch?.length > 50) " class="text-xs mb-2 text-red-500">* The export feature is currently limited to 1M opportunities. (The monthly company average is around 250k)</div>
        </div>


        <div class="py-16 flex items-center justify-center">
            <button
                @click="exportOpportunities"
                class="rounded flex items-center space-x-2 text-blue-500 px-3 py-2 rounded border border-blue-500 bg-white hover:bg-blue-50/50"
                :class="loadingTotal ? 'opacity-40 cursor-not-allowed' : ''"
                :disabled="loadingTotal"
            >
                    <span>Download Opportunities</span><i class="fal fa-download text-xl" />
                </button>
            <div v-if="downloading" class="absolute w-full h-full inset-0 bg-black/50 flex items-center justify-center backdrop-blur-[2px]">
                <div class="text-2xl font-bold text-white">Downloading Opportunities</div>
            </div>
        </div>

    </div>
</template>

<script>
import Hero from '../components/layout/hero.vue'
import dayjs from 'dayjs'
import Toggle from '../components/partials/toggle.vue'
import MultiSelect from '../helpers/multiSelect.vue'
import downloadCsv from '../helpers/download-csv.js'
import Mango from '../helpers/mango'
import Swal from 'sweetalert2'
import latestCutoff from '../helpers/latestCutoff'
import axios from 'axios'

let baseFilters = {
    branch: [],
    streetNumber: null,
    street: null,
    city: null,
    owner: null,
    contractSigned: null,
    insuranceAccepted: null,
    installationStarted: null,
    revenueMin: null,
    revenueMax: null,
    estimateMin: null,
    estimateMax: null,
    address: {
        formatted: null
    },
    rangeStart: dayjs().subtract(3, 'months').format('YYYY-MM-DD'),
    rangeEnd: dayjs().format('YYYY-MM-DD'),
}

export default {
    components: {Hero, Toggle, MultiSelect},
    inject: ['store'],
    data() {
        return {
            totalOps: 0,
            branchEntries: [],
            baseFilters,
            downloading: false,
            block: null,
            debounceTimer: null,
            loadingTotal: false,
            calendarDates: true,
            filters: JSON.parse(JSON.stringify(baseFilters)),
        }
    },
    watch: {
        // query: {
        //     handler: 'debouncedSearch',
        //     immediate: true
        // },
        block: 'selectPastBlock',
        calendarDates: 'selectPastBlock',
    },
    methods: {
        dayjs,
        selectPastBlock() {
            if (!this.block) return
            if (this.calendarDates) {
                this.filters.rangeStart = dayjs().subtract(1, this.block).startOf(this.block).format('YYYY-MM-DD')
                this.filters.rangeEnd = dayjs().subtract(1, this.block).endOf(this.block).format('YYYY-MM-DD')
            } else {
                this.filters.rangeStart = dayjs().subtract(1, this.block).format('YYYY-MM-DD')
                this.filters.rangeEnd = dayjs().format('YYYY-MM-DD')
            }
        },
        async exportOpportunities() {
            if (this.loadingTotal) {
                return setTimeout(() => {
                    this.exportOpportunities()
                }, 500);
            }
            await this.getTotal()
            if (this.totalOps > 5000) {
                axios.post(`${this.store.api}/controllers/exportOpportunities`, {query: this.query})
                return Swal.fire({html: `This export includes ${this.totalOps.toLocaleString()} opportunities!<br><br>Your export will be processed in the background and emailed to ${this.store?.user?.email}.<br><br>Should only take a couple minutes...`, icon: 'info'})
            }
            this.downloading = true
            let query = {...this.query}
            query.fields = ['branch','address','title','status','revenue','estimate','created','updated','contractSigned','insuranceAccepted','shotgun','ownerInfo','author','timestamps','ktwc','installationStarted']
            query.limit = 10000
            query.depthLimit = 1

            let inRange = (l, param) => {
                let c = query.search.$or.find(o => o.created)?.created
                param = new Date(l?.timestamps?.[param] || l?.[param]?.dateChanged || l?.[param])
                return param > c.$gt && param < c.$lt
            }

            let opportunities = await Mango.opportunities(query)
            let csvContent = "data:text/csv;charset=utf-8,"
                + "Owner Name,Address,City,State,Status,Knock Date,Talk Date,Walk Date,Soft Set Date,Contingency Date,Revenue Date,Estimate Date,Installation Started Date,Installation Revenue,Branch,Entity Type,Revenue,Primary Rep,Shotgun,Created,Updated\n"
                + opportunities?.filter(o => o.branch?.status != 'closed')?.map(o => {
                    return `"${(`${o?.ownerInfo?.firstName || ''} ${o.ownerInfo?.lastName || ''}`)?.trim?.()?.replace?.(/[^a-zA-Z0-9\s\-]/g, '')}",
                        "${o.address?.address?.trim?.()?.replace?.(/[^a-zA-Z0-9\s\-]/g, '') || ''}",
                        "${o.address?.city?.trim?.()?.replace?.(/[^a-zA-Z0-9\s\-]/g, '') || ''}",
                        "${o.address?.state?.trim?.()?.replace?.(/[^a-zA-Z0-9\s\-]/g, '') || ''}",
                        "${o.status || ''}",

                        "${o.ktwc?.knock && inRange(o, 'knock') ? dayjs(o.timestamps.knock).format('MM/DD/YY') : ''}",
                        "${o.ktwc?.talk && inRange(o, 'talk') ? dayjs(o.timestamps.talk).format('MM/DD/YY') : ''}",
                        "${o.ktwc?.walk && inRange(o, 'walk') ? dayjs(o.timestamps.walk).format('MM/DD/YY') : ''}",
                        "${o.timestamps?.softSet && inRange(o, 'softSet') ? dayjs(o.timestamps?.softSet).format('MM/DD/YY') : ''}",
                        "${o.ktwc?.contingency && inRange(o, 'contingency') ? dayjs(o.timestamps.contingency).format('MM/DD/YY') : ''}",
                        "${o.timestamps?.revenue && inRange(o, 'revenue') ? dayjs(o.timestamps?.revenue).format('MM/DD/YY') : ''}",
                        "${o.timestamps?.estimate && inRange(o, 'estimate') ? dayjs(o.timestamps?.estimate).format('MM/DD/YY') : ''}",
                        "${(o?.installationStarted?.value && inRange(o, 'installationStarted')) ? dayjs(o?.installationStarted?.dateChanged).format('MM/DD/YY') : ''}",
                        "${(o?.installationStarted?.value && inRange(o, 'installationStarted')) ? (o?.installationStarted?.reportedRevenue || 0)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0'}",

                        "${o.branch?.title}",
                        "${o.branch?.entityType}",

                        "${(o.revenue ||0)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || '$0'}",
                        "${[o.author]?.map(s => s?.firstName + ' ' + s?.lastName).join(' / ') || ''}",
                        "${(o?.shotgun || [])?.map(s => s?.firstName + ' ' + s?.lastName).join(' / ') || ''}",
                        "${dayjs(o.created).format('MM/DD/YY')}",
                        "${dayjs(o.updated).format('MM/DD/YY')}"`.replace(/\n/g, '').replace(/\s+/g, ' ').replaceAll('", "', '","')
                }).join('\n')
            downloadCsv(csvContent, `Opportunities.csv`)
            this.downloading = false
        },
        async getTotal() {
            this.loadingTotal = true
            let q = {...this.query}
            q.fields = ['id']
            q.limit = 1
            q.verbose = true
            let r1 = await Mango.opportunities(q)
            this.totalOps = r1?.count
            let startRange = latestCutoff(dayjs(this.filters.rangeStart))
            if (dayjs().diff(startRange, 'days') > 45) {
                let r2 = await Mango.opportunitiesArchive(q)
                this.totalOps += (r2?.count || 0)
            }
            this.loadingTotal = false
        }
    },
    computed: {
        daysInRange() {
            if (!this.filters.rangeStart || !this.filters.rangeEnd) return 0
            return dayjs(this.filters.rangeEnd).diff(this.filters.rangeStart, 'days') + 1
        },
        calendar() {
            let firstDayOfTheMonth = this.filters.rangeStart ? dayjs(this.filters.rangeStart).startOf('month') : null
            let lastDayOfTheMonth = this.filters.rangeEnd ? dayjs(this.filters.rangeEnd).endOf('month') : null
            let calendar = {months: []}
            if (firstDayOfTheMonth && lastDayOfTheMonth) {
                let month = firstDayOfTheMonth
                console.log('month', month)
                while (month.isBefore(lastDayOfTheMonth)) {
                    calendar.months.push({month: month.format('MMMM YYYY'), days: []})
                    let daysInMonth = month.daysInMonth()
                    for (let i = 1; i <= daysInMonth; i++) {
                        let day = month.date(i)
                        calendar.months[calendar.months.length - 1].days.push(day)
                    }
                    // Pad the front and back of the calendar with empty days so that the first day of the calendar appears on the right day of the week
                    let firstDay = calendar.months[calendar.months.length - 1].days[0].day()
                    for (let i = 0; i < firstDay; i++) {
                        calendar.months[calendar.months.length - 1].days.unshift(null)
                    }
                    let lastDay = calendar.months[calendar.months.length - 1].days[calendar.months[calendar.months.length - 1].days.length - 1].day()
                    for (let i = lastDay; i < 6; i++) {
                        calendar.months[calendar.months.length - 1].days.push(null)
                    }
                    month = month.add(1, 'month')
                }
            }
            return calendar
        },
        admin() {
            return this.store?.user?.roles?.includes('admin')
        },
        branches() {
            if (!this.admin) {
                return this.store?.user?.branches || []
            }
        },
        branchSearchFilters() {
            if (this.branches) {
                return this.branches.map(branch => `objectID:${branch.id}`).join(' OR ');
            }
        },
        filterSearch() {
            let startRange = latestCutoff(dayjs(this.filters.rangeStart))
            let endRange = latestCutoff(dayjs(this.filters.rangeEnd).add(1, 'day'))

            let search = {}
            if (this.filters.branch?.length) search.branch = {$in: this.filters.branch}
            if (this.filters?.address?.id) search['address.id'] = this.filters.address.id
            if (this.filters?.streetNumber) search['address.number'] = this.filters.streetNumber
            if (this.filters?.street) search['address.street'] = {$regex: this.filters.street, $options: 'i'}
            if (this.filters?.city) search['address.city'] = {$regex: this.filters.city, $options: 'i'}
            if (this.filters?.owner) search['ownerInfo.lastName'] = {$regex: this.filters.owner, $options: 'i'}
            if (this.filters?.contractSigned) search.contractSigned = {$ne: null}
            if (this.filters?.insuranceAccepted) search.insuranceAccepted = {$ne: null}
            if (this.filters?.retail) search.retail = true
            if (this.filters?.installationStarted) {
                search['installationStarted.value'] = true
                search['installationStarted.dateChanged'] = { $gt: startRange, $lt: endRange }
            }
            if (this.filters?.revenueMin) search.revenue = {$gte: this.filters.revenueMin}
            if (this.filters?.revenueMax) search.revenue = {...(search?.revenue || {}), $lte: this.filters.revenueMax}
            if (this.filters?.estimateMin) search.estimate = {$gte: this.filters.estimateMin}
            if (this.filters?.estimateMax) search.estimate = {...(search?.estimate || {}), $lte: this.filters.estimateMax}

            search.$or =  [
                { "timestamps.knock": { $gt: startRange, $lt: endRange } },
                { "timestamps.talk": { $gt: startRange, $lt: endRange } },
                { "timestamps.walk": { $gt: startRange, $lt: endRange } },
                { "timestamps.contingency": { $gt: startRange, $lt: endRange } },
                { "timestamps.revenue": { $gt: startRange, $lt: endRange } },
                { "timestamps.softSet": { $gt: startRange, $lt: endRange } },
                { "timestamps.estimate": { $gt: startRange, $lt: endRange } },
                { insuranceAccepted: { $gt: startRange, $lt: endRange } },
                { contractSigned: { $gt: startRange, $lt: endRange } },
                { "installationStarted.dateChanged": { $gt: startRange, $lt: endRange } },
                { created: { $gt: startRange, $lt: endRange } },
            ]
            // if (this.filters?.rangeStart) search.created = {$gt: latestCutoff(dayjs(this.filters.rangeStart))}
            // if (this.filters?.rangeEnd) search.created ? search.created.$lt = latestCutoff(dayjs(this.filters.rangeEnd).add(1, 'day')) : {$lt: latestCutoff(dayjs(this.filters.rangeEnd).add(1, 'day'))}
            return search
        },
        query() {
            if (this.algoliaSearch) return
            let query = {search: {}, sort: {created: -1}}
            if (Array.isArray(this.collectionModel?.adminIndex)) {
                query.fields = [...query.fields, ...this.collectionModel.adminIndex]
            }
            if (this.branches) {
                if (this.collectionModel.name == 'members') query.search.branches = {$in: this.branches.map(b => b.id)}
                else query.search.branch = {$in: this.branches.map(b => b.id)}
            }

            if (this.filterSearch) query.search = {...query.search, ...this.filterSearch}
            return query
        },

        debouncedSearch() {
            let search = async (n) => {
                this.loadingTotal = true
                let q = {...n}
                q.fields = ['id']
                q.limit = 1
                q.verbose = true
                // If the startRange is more than 45 days old...
                let r1 = await Mango.opportunities(q)
                this.totalOps = r1?.count
                if (dayjs().diff(dayjs(q.search.created.$gt), 'days') > 45) {
                    let r2 = await Mango.opportunitiesArchive(q)
                    this.totalOps += (r2?.count || 0)
                }
                this.loadingTotal = false
            }
            return (...args) => {
                // savingCart.value = true
                clearTimeout(this.debounceTimer);
                this.debounceTimer = setTimeout(() => { search.apply(this, args) }, 500);
            }
        }
    }
}
</script>

<style lang="postcss" scoped>
.filters input {
    @apply border border-gray-300 rounded-sm outline-blue-400 dark:outline-blue-500/40 px-2 py-1 text-sm dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-600 w-full
}
</style>
