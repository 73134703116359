<template>
  <div class="export-report">
    <div class="header mb-8">
      <h2 class="text-2xl font-semibold mb-4">Export Opportunities</h2>
      <p class="text-gray-600 mb-6">Generate and download custom reports for the selected date range</p>
    </div>

    <!-- Report Configuration -->
    <div class="report-configuration bg-white rounded-lg border border-gray-200 shadow-sm p-6 mb-8">
      <h3 class="text-lg font-medium mb-4">Configure Your Report</h3>

      <!-- Report Type Selection -->
      <div class="mb-6">
        <label class="block text-sm font-medium text-gray-700 mb-2">Report Type</label>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div
            v-for="type in reportTypes"
            :key="type.id"
            @click="selectedReportType = type.id"
            class="report-type-card p-4 border rounded-lg cursor-pointer transition-all duration-200"
            :class="selectedReportType === type.id ? 'border-blue-500 bg-blue-50' : 'border-gray-200 hover:border-gray-300'"
          >
            <div class="flex items-center">
              <div class="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center mr-3">
                <i :class="`fas ${type.icon} text-blue-600`"></i>
              </div>
              <div>
                <h4 class="font-medium">{{ type.name }}</h4>
                <p class="text-xs text-gray-500">{{ type.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Report Options -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <!-- Include Options -->
        <div>
          <label class="block text-sm font-medium text-gray-700 mb-2">Include in Report</label>
          <div class="space-y-2">
            <div
              v-for="option in includeOptions"
              :key="option.id"
              class="flex items-center"
            >
              <input
                type="checkbox"
                :id="`include-${option.id}`"
                v-model="selectedIncludes"
                :value="option.id"
                class="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
              />
              <label :for="`include-${option.id}`" class="ml-2 text-sm text-gray-700">{{ option.label }}</label>
            </div>
          </div>
        </div>

        <!-- Format Options -->
        <div>
          <label class="block text-sm font-medium text-gray-700 mb-2">Report Format</label>
          <div class="space-y-2">
            <div
              v-for="format in formatOptions"
              :key="format.id"
              class="flex items-center"
            >
              <input
                type="radio"
                :id="`format-${format.id}`"
                v-model="selectedFormat"
                :value="format.id"
                class="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
              />
              <label :for="`format-${format.id}`" class="ml-2 text-sm text-gray-700">{{ format.label }}</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Advanced Options -->
      <div class="mb-6">
        <button
          @click="showAdvancedOptions = !showAdvancedOptions"
          class="text-sm text-blue-600 hover:text-blue-800 flex items-center"
        >
          <i :class="showAdvancedOptions ? 'fas fa-chevron-down' : 'fas fa-chevron-right'" class="mr-2"></i>
          Advanced Options
        </button>

        <div v-if="showAdvancedOptions" class="mt-4 space-y-4 p-4 bg-gray-50 rounded-lg">
          <!-- Group By Option -->
          <div>
            <label class="block text-sm font-medium text-gray-700 mb-2">Group Results By</label>
            <select
              v-model="groupBy"
              class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
              <option value="none">No Grouping</option>
              <option value="branch">Branch</option>
              <option value="team">Team</option>
              <option value="date">Date</option>
              <option value="month">Month</option>
            </select>
          </div>

          <!-- Sort Options -->
          <div>
            <label class="block text-sm font-medium text-gray-700 mb-2">Sort Results By</label>
            <div class="flex space-x-3">
              <select
                v-model="sortBy"
                class="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
                <option value="date">Date</option>
                <option value="revenue">Revenue</option>
                <option value="contracts">Contracts</option>
                <option value="knock">Knock</option>
                <option value="talk">Talk</option>
                <option value="walk">Walk</option>
                <option value="contingency">Contingency</option>
              </select>

              <select
                v-model="sortDirection"
                class="w-32 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </div>
          </div>

          <!-- Limit Results -->
          <div>
            <label class="block text-sm font-medium text-gray-700 mb-2">Limit Results</label>
            <input
              type="number"
              v-model="limitResults"
              min="1"
              max="1000"
              class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
            <p class="text-xs text-gray-500 mt-1">Leave empty for no limit (max 1000)</p>
          </div>
        </div>
      </div>

      <!-- Generate Button -->
      <div class="text-right">
        <button
          @click="generateReport"
          class="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          :disabled="loading"
        >
          <i class="fas fa-file-export mr-2"></i>
          {{ loading ? 'Generating...' : 'Generate Report' }}
        </button>
      </div>
    </div>

    <!-- Recent Reports -->
    <div class="recent-reports bg-white rounded-lg border border-gray-200 shadow-sm p-6">
      <h3 class="text-lg font-medium mb-4">Recent Reports</h3>

      <!-- Loading State -->
      <Spinner v-if="recentReportsLoading" :small="true" class="w-10 h-10 mx-auto my-8" />

      <!-- Empty State -->
      <div v-else-if="!recentReports.length" class="text-center py-8">
        <div class="text-4xl text-gray-300 mb-3"><i class="far fa-file-alt"></i></div>
        <p class="text-gray-500">No recent reports found</p>
        <p class="text-gray-400 text-sm mt-1">Generate a new report to see it here</p>
      </div>

      <!-- Reports List -->
      <div v-else class="divide-y">
        <div
          v-for="report in recentReports"
          :key="report.id"
          class="py-4 flex items-center justify-between"
        >
          <div class="flex items-center">
            <div class="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center mr-3">
              <i :class="`fas ${getReportIcon(report.type)} text-gray-500`"></i>
            </div>
            <div>
              <h4 class="font-medium">{{ report.name }}</h4>
              <p class="text-xs text-gray-500">
                {{ formatDate(report.created) }} • {{ formatFileSize(report.size) }}
              </p>
            </div>
          </div>

          <div class="flex space-x-2">
            <button
              @click="downloadReport(report)"
              class="p-2 text-blue-600 hover:text-blue-800"
              title="Download"
            >
              <i class="fas fa-download"></i>
            </button>
            <button
              @click="deleteReport(report.id)"
              class="p-2 text-red-600 hover:text-red-800"
              title="Delete"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'ExportReport',
  props: {
    dateRange: {
      type: Object,
      required: true
    },
    selectedBranches: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      recentReportsLoading: false,
      recentReports: [],
      showAdvancedOptions: false,

      // Report configuration
      selectedReportType: 'performance',
      selectedIncludes: ['revenue', 'contracts', 'ktwc'],
      selectedFormat: 'csv',
      groupBy: 'none',
      sortBy: 'date',
      sortDirection: 'desc',
      limitResults: '',

      // Options
      reportTypes: [
        {
          id: 'performance',
          name: 'Performance Report',
          icon: 'fa-chart-line',
          description: 'Revenue, contracts, and KTWC metrics'
        },
        {
          id: 'team',
          name: 'Team Report',
          icon: 'fa-users',
          description: 'Team member performance breakdown'
        },
        {
          id: 'opportunity',
          name: 'Opportunity Report',
          icon: 'fa-handshake',
          description: 'Detailed opportunity tracking'
        }
      ],
      includeOptions: [
        { id: 'revenue', label: 'Revenue' },
        { id: 'contracts', label: 'Contracts' },
        { id: 'ktwc', label: 'KTWC Metrics' },
        { id: 'conversion', label: 'Conversion Rates' },
        { id: 'notes', label: 'Notes & Comments' },
        { id: 'contact', label: 'Contact Information' }
      ],
      formatOptions: [
        { id: 'csv', label: 'CSV (Excel, Google Sheets)' },
        { id: 'pdf', label: 'PDF Document' },
        { id: 'json', label: 'JSON Data' }
      ]
    };
  },
  inject: ['axios', 'store'],
  mounted() {
    this.fetchRecentReports();
  },
  methods: {
    async fetchRecentReports() {
      this.recentReportsLoading = true;
      try {
        const response = await this.axios.get(`${this.store.api}/controllers/reports/recent`);
        if (response?.data?.reports) {
          this.recentReports = response.data.reports;
        }
      } catch (error) {
        console.error('Error fetching recent reports:', error);
      } finally {
        this.recentReportsLoading = false;
      }
    },

    async generateReport() {
      if (this.loading) return;

      this.loading = true;
      try {
        const payload = {
          type: this.selectedReportType,
          format: this.selectedFormat,
          dateRange: this.dateRange,
          branches: this.selectedBranches.map(b => b.id),
          includes: this.selectedIncludes,
          options: {
            groupBy: this.groupBy,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
            limit: this.limitResults || null
          }
        };

        const response = await this.axios.post(
          `${this.store.api}/controllers/reports/generate`,
          payload,
          { responseType: 'blob' }
        );

        // Create filename based on report type and date
        const dateStr = dayjs().format('YYYY-MM-DD');
        let filename = `${this.selectedReportType}-report-${dateStr}`;

        // Add file extension based on format
        switch (this.selectedFormat) {
          case 'csv':
            filename += '.csv';
            break;
          case 'pdf':
            filename += '.pdf';
            break;
          case 'json':
            filename += '.json';
            break;
        }

        // Create download link
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Refresh recent reports list
        this.fetchRecentReports();
      } catch (error) {
        console.error('Error generating report:', error);
        this.$toast.error('Failed to generate report. Please try again.');
      } finally {
        this.loading = false;
      }
    },

    async downloadReport(report) {
      try {
        const response = await this.axios.get(
          `${this.store.api}/controllers/reports/download/${report.id}`,
          { responseType: 'blob' }
        );

        // Create download link
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', report.name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading report:', error);
        this.$toast.error('Failed to download report. Please try again.');
      }
    },

    async deleteReport(reportId) {
      if (!confirm('Are you sure you want to delete this report?')) return;

      try {
        await this.axios.delete(`${this.store.api}/controllers/reports/${reportId}`);
        this.recentReports = this.recentReports.filter(r => r.id !== reportId);
        this.$toast.success('Report deleted successfully');
      } catch (error) {
        console.error('Error deleting report:', error);
        this.$toast.error('Failed to delete report. Please try again.');
      }
    },

    formatDate(dateString) {
      return dayjs(dateString).format('MMM D, YYYY [at] h:mm A');
    },

    formatFileSize(bytes) {
      if (!bytes) return '0 Bytes';

      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    },

    getReportIcon(type) {
      const reportType = this.reportTypes.find(r => r.id === type);
      return reportType ? reportType.icon : 'fa-file-alt';
    }
  }
};
</script>

<style scoped>
.export-report {
  @apply w-full;
}
</style>
