<template>
    <div class="border dark:border-0 bg-white dark:bg-gray-800 fix-rounding-safari w-full h-full relative flex flex-col overflow-scroll">

        <Modal v-if="selectedPhoto" @hide="selectedPhoto = null" maxWidth="max-w-4xl !p-0">
            <img :src="selectedPhoto" class="w-full" />
        </Modal>

        <div
            class="top-4 md:top-8 px-4 md:px-8 inset-x-0 flex flex-col-reverse md:flex-row z-50 flex justify-between items-start md:space-x-8 pointer-events-none"
            :class="selectedView == 'opportunities' ? 'absolute' : 'py-8'"
        >

            <!-- p-4 bg-white rounded-xl shadow-card -->
            <div class="flex flex-col items-start space-y-2 pointer-events-auto">

                <div v-if="admin" class="flex bg-white shadow-xl rounded-xl divide-x overflow-hidden border">
                    <label
                        v-for="t in entityTypeOptions" :key="t"
                        :class="{'text-blue-500 bg-blue-50': entityTypes.includes(t)}"
                        class="px-3 py-2 flex items-center space-x-1"
                    >
                        <input type="checkbox" class="" v-model="entityTypes" :value="t" />
                        <span class="text-2xs md:text-xs">{{ t.toTitleCase() }}</span>
                    </label>
                </div>

                <MultiSelect
                    v-if="admin || branches?.length > 1"
                    collection="branches"
                    class="w-full rounded-xl flex bg-white shadow-xl bg-white text-xs md:text-sm"
                    v-model:modelSelectedIds="branchIds"
                    v-model:modelSelectedEntries="branchEntries"
                    buttonText="Filter Branches"
                    placeholder="Search Branches"
                    :inputStyle="`w-64 text-xs md:text-sm !rounded-xl !border ${branchIds?.length ? `-mt-2` : ''}`"
                    :algoliaFilters="branchSearchFilters"
                    :multiple="true"
                    :create="false"
                    :movable="false"
                />

                <div class="drop-shadow-xl w-full flex flex-col items-start">
                    <MultiSelect
                        collection="members"
                        class="w-full rounded-xl flex bg-white bg-white text-xs md:text-sm"
                        v-model:modelSelectedIds="repIds"
                        v-model:modelSelectedEntries="repEntries"
                        buttonText="Filter Reps"
                        placeholder="Search Reps"
                        :inputStyle="`w-64 text-xs md:text-sm !rounded-xl !border ${repIds?.length ? `-mt-2` : ''}`"
                        :algoliaFilters="repSearchFilters"
                        :multiple="true"
                        :create="false"
                        :movable="false"
                    />

                    <div v-if="route" class="flex bg-white shadow-xl rounded-b-xl overflow-hidden border text-xs md:text-sm p-2 space-x-2 font-semibold !-mt-2 pt-4">
                        <div class="text-green-600">{{ convertDuration(route.duration) }}</div>
                        <div class="">{{ (route.distance / 1609.34).toFixed(2) }} mi</div>
                    </div>
                </div>

                <div class="flex bg-white shadow-xl rounded-xl divide-x overflow-hidden border text-xs md:text-base">
                    <button
                        v-for="v in viewOptions" :key="v"
                        class="px-3 py-2" :class="{'text-blue-500 bg-blue-50': v == selectedView}"
                        @click="selectedView = v"
                    >
                        <i class="fal" :class="`fa-${viewOptionIcons[v]}`" />
                    </button>
                </div>

            </div>



            <div class="hidden 3xl:flex w-full max-w-xs">
                <div v-if="partner" class="bg-white shadow-xl rounded-xl divide-x p-4 w-full">
                    <div class="flex items-center space-x-4">
                        <People :people="[partner]" class="shrink-0" />
                        <div class="w-full flex justify-between items-center">
                            <div>
                                <div class="opacity-40 text-xs uppercase">Partner</div>
                                <h1 class="text-lg md:text-xl">{{ partner.title }}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div v-if="partner" class="hidden 3xl:flex bg-white shadow-xl rounded-xl divide-x p-4">
                <div class="flex items-center space-x-4">
                    <People :people="[store.user]" class="shrink-0" />
                    <div class="w-full flex justify-between items-center">
                        <div>
                            <div class="opacity-40 text-xs">Joined: {{ dayjs(store.user?.created).format('MMM D, YYYY') }}</div>
                            <h1 class="text-lg md:text-xl">{{ store.user.title }}</h1>
                            <div class="opacity-50">{{ store.user.branches?.[0]?.title }}</div>
                        </div>
                    </div>
                </div>
            </div> -->

        </div>

        <!-- Main map container -->
        <div class="w-full flex-grow overflow-scroll">
            <div class="w-full h-full">
                <Mapbox
                    :markers="clusterMarkers"
                    :heatmapData="heatmapPoints"
                    :enableClustering="enableClustering"
                    ref="map"
                    @moveend="handleMapMoveEnd"
                />
            </div>

            <div v-show="selectedView == 'reps'" class="w-full ">
                <Mango collection="members" :infinite="true" :query="repQuery" @update:data="reps = $event" class="md:pt-16">
                    <div v-if="repStats?.length" class="flex flex-col items-start divide-y">
                        <div class="flex font-mono mx-auto flex sticky top-0 md:top-0 z-20 shadow-card border-b-2">
                            <div class="flex items-center shrink-0 w-32 md:w-[17rem] md:max-w-[17rem] pr-4 md:pr-0 bg-white bg-slate-100 left-0 sticky z-10 pl-4 border-r-2"><span class=" md:block mr-1">Sales</span>Rep</div>
                            <div v-for="total, key in repStats[0].totals" :key="key" :class="theme[key]" class="w-32 shrink-0 py-2 px-4 flex flex-col items-center justify-center relative truncate shadow-card">
                                <!-- <div class="md:hidden text-xs lg:text-2xs 2xl:text-xs tracking-widest uppercase leading-tight opacity-50 truncate">{{ key[0] }}</div> -->
                                <div class="text-xs tracking-widest uppercase leading-tight opacity-50 truncate">{{ key.toTitleCase('camel').replace('Opportunities', 'Ops') }}</div>
                            </div>
                        </div>
                        <div v-for="rep in repStats" :key="rep.id" class="hover:bg-gray-50 cursor-pointer mx-auto">
                            <div class="flex">
                                <div class="flex flex-col md:flex-row w-32 md:space-x-4 shrink-0 md:w-[17rem] md:max-w-[17rem] pr-4 md:pr-0 left-0 pl-4 sticky z-10 bg-white border-r-2">
                                    <People :people="[rep]" class="hidden md:block shrink-0 self-center pt-2 md:pt-0" />
                                    <button @click="setRep(rep)" class="truncate text-xs md:hidden py-2 text-left">{{ rep.title }}</button>
                                    <div class="hidden md:flex justify-between items-center">
                                        <div class="py-4">
                                            <div class="opacity-40 text-2xs">Joined: {{ dayjs(rep?.created).format('MMM D, YYYY') }}</div>
                                            <button class="hover:underline text-left" @click="setRep(rep)">{{ rep.title }}</button>
                                            <div v-if="branchIds?.length != 1 && branches?.length != 1" class="opacity-50 text-xs">{{ rep.branches?.[0]?.title }}</div>
                                            <div v-if="rep?.emoji" class="flex items-center space-x-2">
                                                <div v-for="v, e in rep.emoji.reduce((a,e) => {a[e] += 1; return a}, {'🦁':0,'🐋':0,'🦣':0})" :key="e" class="text-xs">
                                                    <div v-if="v">{{ e }} {{v}}x</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex font-mono mx-auto divide-x">
                                    <div v-for="total, key in rep.totals" :key="key" :class="theme[key]" class="w-32 shrink-0 bg-opacity-20 px-2 flex flex-col items-center justify-center">
                                        <div class="text-lg">{{ isNaN(total) ? total : total.toLocaleString() || 0 }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Mango>
            </div>

            <div v-show="selectedView == 'branches'" class="w-full ">
                <div class="md:pt-16" v-if="branchStats?.length">
                    <div class="flex flex-col items-start divide-y">
                        <div class="flex font-mono mx-auto flex sticky top-0 md:top-0 z-20 shadow-card border-b-2">
                            <div class="flex items-center shrink-0 w-32 md:w-[17rem] md:max-w-[17rem] pr-4 md:pr-0 bg-white bg-slate-100 left-0 sticky z-10 pl-4 border-r-2">Branch</div>
                            <div v-for="total, key in branchStats[0].totals" :key="key" :class="theme[key]" class="w-32 shrink-0 py-2 px-4 flex flex-col items-center justify-center relative truncate shadow-card">
                                <!-- <div class="md:hidden text-xs lg:text-2xs 2xl:text-xs tracking-widest uppercase leading-tight opacity-50 truncate">{{ key[0] }}</div> -->
                                <div class="text-xs tracking-widest uppercase leading-tight opacity-50 truncate">{{ key.toTitleCase('camel').replace('Opportunities', 'Ops') }}</div>
                            </div>
                        </div>
                        <div v-for="branch in branchStats" :key="branch.id" class="hover:bg-gray-50 cursor-pointer mx-auto">
                            <div class="flex">
                                <div class="flex flex-col md:flex-row w-32 md:space-x-4 shrink-0 md:w-[17rem] md:max-w-[17rem] pr-4 md:pr-0 left-0 pl-4 sticky z-10 bg-white border-r-2">
                                    <People :people="[branch]" class="hidden md:block shrink-0 self-center pt-2 md:pt-0" />
                                    <button @click="setBranch(branch)" class="truncate text-xs md:hidden py-2 text-left">{{ branch.title }}</button>
                                    <div class="hidden md:flex justify-between items-center">
                                        <div class="py-4">
                                            <div class="opacity-40 text-2xs">Joined: {{ dayjs(branch?.created).format('MMM D, YYYY') }}</div>
                                            <button class="hover:underline text-left" @click="setBranch(branch)">{{ branch.title }}</button>
                                            <!-- <div v-if="branchIds?.length != 1 && branches?.length != 1" class="opacity-50 text-xs">{{ rep.branches?.[0]?.title }}</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="flex font-mono mx-auto divide-x">
                                    <div v-for="total, key in branch.totals" :key="key" :class="theme[key]" class="w-32 shrink-0 bg-opacity-20 px-2 flex flex-col items-center justify-center">
                                        <div class="text-lg">{{ isNaN(total) ? total : total.toLocaleString() || 0 }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="selectedView == 'partners'" class="w-full px-8 pb-16">
                <div class="text-center text-3xl mt-8">Select a Partner</div>
                <Mango
                    collection="members" :query="{search: {roles: 'seniorPartner'}}" :infinite="true" v-slot="{data: partners, loading}"
                    class="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 lg:gap-16 gap-6 mt-16 max-w-7xl mx-auto"
                >
                    <Spinner v-if="loading" />
                    <button
                        v-for="partner in partners"
                        :key="partner.id"
                        :to="`/${path}/${partner?.id}`"
                        @click="setPartner(partner)"
                        class="flex items-center justify-start hover:-rotate-1 hover:scale-105 transition-all group space-x-6 w-full text-left"
                    >
                        <div
                            :style="`background-image: url('${partner.image?.url}')`"
                            class="bg-cover bg-center w-20 h-20 shrink-0 rounded-full flex items-center justify-center bg-gray-100 border-2"
                        ><i v-if="!partner.image?.url" class="fad text-2xl fa-building"/>
                        </div>
                        <div class="flex flex-col">
                            <!-- <div class="opacity-30 text-2xs uppercase tracking-widest font-bold">Since {{ dayjs(partner.created).format('MMM \'YY') }}</div> -->
                            <div class="opacity-30 text-2xs uppercase tracking-widest font-bold">{{ partner?.branches?.length || 0 }} Branches</div>
                            <div class="text-lg font-semibold z-10 group-hover:underline">{{ partner.title }}</div>
                        </div>
                    </button>
                </Mango>
            </div>

        </div>

    </div>

</template>

<script>
import Mapbox from '../components/network/mapbox.vue'
import Modal from '../components/layout/modal.vue'
import MultiSelect from '../helpers/multiSelect.vue'
import Mango from '../helpers/mango'
import People from '../components/layout/people.vue'

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration';
import axios from 'axios'
dayjs.extend(duration);

export default {
    props: [],
    inject: ['darkMode','store'],
    components: {Mapbox, Modal, MultiSelect, People},
    data() {
        return {
            loading: false,
            clusterData: null,
            enableClustering: false
        }
    },
    // async created() {
    //     let admin = this.store?.user?.roles?.includes('admin')
    //     let myBranchIds = this.store?.user?.branches.map(b => b.id)
    //     let search = {}
    //     if (!admin) search.id = {$in: myBranchIds}
    //     this.branchesData = await Mango.branches({search, fields: ['title','image','created'], limit: 1000})
    // },
    created() {
        // Create debounced version of fetchClusterData
        this.debouncedFetchClusterData = this.debounce(this.fetchClusterData, 1000);
    },
    methods: {
        // Debounce utility function
        debounce(fn, delay) {
            let timeoutId;
            return function (...args) {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => fn.apply(this, args), delay);
            };
        },

        async fetchClusterData() {
            console.log('map', this.$refs.map);
            if (!this.$refs.map?.getBounds) return;
            try {
                const bounds = this.$refs.map?.getBounds();
                if (!bounds) return;

                const zoom = this.$refs.map?.getZoom();
                if (zoom === undefined) return;

                const params = new URLSearchParams({
                    minLng: bounds.getWest(),
                    maxLng: bounds.getEast(),
                    minLat: bounds.getSouth(),
                    maxLat: bounds.getNorth(),
                    zoom: Math.floor(zoom)
                });

                const response = await axios.get(`${this.store.api}/controllers/heatmap/cluster?${params}`);

                this.clusterData = response.data;
            } catch (error) {
                console.error('Error fetching cluster data:', error);
            }
        },

        handleMapMoveEnd() {
            this.debouncedFetchClusterData();
        },

        getClusterColor(count) {
            if (count >= 100) return '#ef4444';      // red-500
            if (count >= 50) return '#f97316';      // orange-500
            if (count >= 20) return '#eab308';      // yellow-500
            if (count >= 10) return '#22c55e';      // green-500
            return '#3b82f6';                       // blue-500
        },

        getClusterSize(count) {
            if (count >= 100) return 40;
            if (count >= 50) return 35;
            if (count >= 20) return 30;
            if (count >= 10) return 25;
            return 20;
        }
    },
    computed: {
        heatmapPoints() {
            if (!this.clusterData) return [];
            return this.clusterData.filter(point => point.type === 'heatmap');
        },
        clusterMarkers() {
            if (!this.clusterData) return [];

            // Filter out heat map points
            const clusterPoints = this.clusterData.filter(point => point.type !== 'heatmap');
            let preClustered = clusterPoints?.[0]?.count ? true : false;

            this.enableClustering = !preClustered;
            if (!preClustered) return this.clusterData.map(point => ({
                coordinates: [point.coordinates.lng, point.coordinates.lat],
                status: 'open',
                id: point.id,
                collection: 'opportunities',
                created: new Date(),
                popup: ``
            }));

            // {
            // if (opp?.address?.coordinates?.lat) {
            //     this.opportunityCache.set(opp.id, {
            //         coordinates: [opp?.address?.coordinates?.lng, opp?.address?.coordinates?.lat],
            //         status: opp.status,
            //         updated: opp.updated,
            //         id: opp.id,
            //         author: opp.author,
            //         shotgun: opp.shotgun,
            //         ktwc: opp.ktwc,
            //         insuranceAccepted: opp.insuranceAccepted,
            //         contractSigned: opp.contractSigned,
            //         estimate: opp.estimate,
            //         discussion: opp.discussion,
            //         inspection: opp.inspection,
            //         lastCached: new Date().toISOString(),
            //     }

            return clusterPoints.map(cluster => ({
                coordinates: cluster.coordinates, // Already in [lng, lat] format
                color: this.getClusterColor(cluster.count),
                size: this.getClusterSize(cluster.count),
                content: `
                    <div class="p-2 bg-blue-500 rounded-full border-2 border-white text-white aspect-square shrink-0">
                        ${cluster.count}
                    </div>
                `,
            }));
        }
    },
    async mounted() {
        this.loadingBranches = true;
        this.branchMap = await Mango.branches({
            fields: ['entityType','title','image','created'],
            limit: 999,
            search: {status: 'open'}
        });
        this.loadingBranches = false;
        this.fetchClusterData();
    },
    watch: {
        entityType: {
            async handler() {
                if (this.entityType) {
                    this.branchEntries = [];
                    this.branchIds = [];
                }
            }
        },
        leadMarkers: {
            handler() {
                if (this.repIds?.length === 1) {
                    setTimeout(() => {
                        this.$refs.map?.getOptimalRoute();
                    }, 1500);
                } else {
                    this.$refs.map?.removeRouteLine();
                }
            }
        },
        repEntries: {
            handler() {
                this.partner = null;
            },
            deep: true
        },
        branchEntries: {
            handler() {
                this.partner = null;
            },
            deep: true
        },
        entityTypes: {
            handler() {
                console.log('entityTypes');
                this.partner = null;
            }
        }
    },
}
</script>

<style>
.fix-rounding-safari {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: radial-gradient(white, black);
}
</style>
