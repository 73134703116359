<template>
    <div class="teams-report">
        <div class="header mb-8">
            <h2 class="text-2xl font-semibold mb-4">Team Performance</h2>
            <p class="text-gray-600 mb-6">Team member statistics and performance metrics for the selected date range</p>

            <!-- View Controls -->
            <div class="view-controls flex flex-wrap items-center justify-between mb-6">
                <div class="view-options flex space-x-3">
                    <button @click="viewMode = 'cards'" class="px-3 py-2 rounded-lg border text-sm"
                        :class="{ 'border-blue-500 bg-blue-50 text-blue-700': viewMode === 'cards', 'bg-white': viewMode !== 'cards' }">
                        <i class="fas fa-th-large mr-2"></i>Card View
                    </button>
                    <button @click="viewMode = 'table'" class="px-3 py-2 rounded-lg border text-sm"
                        :class="{ 'border-blue-500 bg-blue-50 text-blue-700': viewMode === 'table', 'bg-white': viewMode !== 'table' }">
                        <i class="fas fa-table mr-2"></i>Table View
                    </button>
                </div>

                <div class="actions flex space-x-3">
                    <button @click="aiOpen = true"
                        class="px-3 py-2 rounded-lg bg-gradient-to-r from-red-500 to-purple-500 text-white text-sm">
                        <i class="fas fa-brain mr-2"></i>Ask AI
                    </button>
                    <button @click="exportData" class="px-3 py-2 rounded-lg border border-gray-300 bg-white text-sm">
                        <i class="fas fa-download mr-2"></i>Export CSV
                    </button>
                </div>
            </div>

            <!-- Search & Sort -->
            <div class="search-sort flex flex-wrap items-center justify-between">
                <div class="search relative w-full md:w-64">
                    <input type="text" v-model="searchQuery" placeholder="Search team members..."
                        class="w-full pl-10 pr-4 py-2 border rounded-lg" />
                    <i class="fas fa-search absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"></i>
                </div>

                <div class="sort-by flex items-center space-x-2 mt-3 md:mt-0">
                    <span class="text-sm text-gray-500">Sort by:</span>
                    <select v-model="sortOption" class="border rounded-lg px-3 py-2 text-sm">
                        <option value="title">Name</option>
                        <option value="revenue">Revenue</option>
                        <option value="contracts">Contracts</option>
                        <option value="knock">Knock</option>
                        <option value="conversion">Conversion Rate</option>
                        <option value="role">Role</option>
                    </select>
                    <button @click="sortDirection = sortDirection === 'asc' ? 'desc' : 'asc'"
                        class="px-2 py-1 border rounded-lg">
                        <i :class="sortDirection === 'asc' ? 'fas fa-sort-up' : 'fas fa-sort-down'"></i>
                    </button>
                </div>
            </div>
        </div>

        <!-- AI Analysis Modal -->
        <Modal v-if="aiOpen" @hide="aiOpen = false" maxWidth="max-w-2xl">
            <div class="flex flex-col space-y-4 !m-0 !p-0">
                <h2 class="text-2xl">AI Analysis</h2>

                <div class="space-y-2">
                    <div v-for="response, i in aiResponses" :key="i" class="p-4 rounded-xl bg-gray-50 space-y-4"
                        v-html="response" />
                </div>

                <textarea class="border-2 rounded p-4" cols="30" rows="10" v-model="aiQuery"
                    placeholder="Ask AI a question about the team data (ie: Who are the top performers and why?)"></textarea>

                <div>
                    <button class="px-3 py-2 rounded bg-gradient-to-r from-red-500 to-purple-500 text-white"
                        @click="queryAi">Submit</button>
                </div>
            </div>

            <div v-if="aiRunning"
                class="fixed inset-0 dark:bg-black/50 bg-white/70 flex items-center justify-center w-full h-screen backdrop-blur">
                <div class="text-lg text-2xl font-semibold">Thinking...</div>
            </div>
        </Modal>

        <!-- Team Data Display -->
        <div class="team-data-display">
            <!-- Loading State -->
            <Spinner v-if="loading" :small="true" class="w-16 h-16 mx-auto mt-16" />

            <!-- Empty State -->
            <div v-else-if="!teamReports.length" class="text-center py-16">
                <div class="text-5xl text-gray-300 mb-4"><i class="far fa-user-circle"></i></div>
                <p class="text-xl text-gray-500 font-medium">No team member data available for the selected filters</p>
                <p class="text-gray-400 mt-2">Try adjusting your filters or date range</p>
            </div>

            <!-- Card View -->
            <div v-else-if="viewMode === 'cards'" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <div v-for="member in filteredTeamMembers" :key="member.id"
                    class="team-card p-6 rounded-lg shadow-md border border-gray-100 bg-white hover:shadow-lg transition-shadow duration-200">
                    <div class="flex items-center mb-4">
                        <div class="w-12 h-12 rounded-full bg-center bg-cover bg-gray-200 mr-4 flex items-center justify-center"
                            :style="member.rep?.image?.url ? `background-image: url(${member.rep.image.url})` : ''">
                            <i v-if="!member.rep?.image?.url" class="fas fa-user text-gray-400"></i>
                        </div>
                        <div>
                            <h3 class="font-medium text-lg">{{ member.rep?.title || 'Unknown Member' }}</h3>
                            <p class="text-gray-500 text-sm">{{ member.role || 'Team Member' }} • {{
                                member.branch?.title || 'Unknown Branch' }}</p>
                        </div>
                    </div>

                    <div class="grid grid-cols-2 gap-3 mb-4">
                        <div class="stat-box p-3 bg-gray-50 rounded-lg text-center">
                            <div class="text-lg font-semibold">{{ formatCurrency(member.revenue) }}</div>
                            <div class="text-xs text-gray-500">Revenue</div>
                        </div>
                        <div class="stat-box p-3 bg-gray-50 rounded-lg text-center">
                            <div class="text-lg font-semibold">{{ formatCurrency(member.mtd) }}</div>
                            <div class="text-xs text-gray-500">MTD</div>
                        </div>
                    </div>

                    <div class="grid grid-cols-2 gap-3 mb-4">
                        <div class="stat-box p-3 bg-gray-50 rounded-lg text-center">
                            <div class="text-lg font-semibold">{{ member.contracts || 0 }}</div>
                            <div class="text-xs text-gray-500">Contracts</div>
                        </div>
                        <div class="stat-box p-3 bg-gray-50 rounded-lg text-center">
                            <div class="text-lg font-semibold">{{ calculateConversion(member.knock, member.contingency) }}%</div>
                            <div class="text-xs text-gray-500">Close Rate</div>
                        </div>
                    </div>

                    <div class="ktwc-stats flex justify-between py-2 border-t border-b mb-4">
                        <div class="text-center">
                            <div class="text-xs text-gray-500">K</div>
                            <div class="font-medium">{{ member.knock || 0 }}</div>
                        </div>
                        <div class="text-center">
                            <div class="text-xs text-gray-500">T</div>
                            <div class="font-medium">{{ member.talk || 0 }}</div>
                        </div>
                        <div class="text-center">
                            <div class="text-xs text-gray-500">W</div>
                            <div class="font-medium">{{ member.walk || 0 }}</div>
                        </div>
                        <div class="text-center">
                            <div class="text-xs text-gray-500">C</div>
                            <div class="font-medium">{{ member.contingency || 0 }}</div>
                        </div>
                    </div>

                    <div class="flex justify-between text-sm">
                        <span>Conversion:</span>
                        <span class="font-medium">{{ calculateConversion(member.talk, member.walk) }}%</span>
                    </div>

                    <div class="mt-4 text-right">
                        <router-link :to="`/member-report/${member.rep?.id}`"
                            class="text-blue-600 hover:text-blue-800 text-sm font-medium">
                            View Details →
                        </router-link>
                    </div>
                </div>
            </div>

            <!-- Table View -->
            <div v-else class="overflow-x-auto">
                <table class="min-w-full bg-white border border-gray-200 shadow-sm rounded-lg">
                    <thead>
                        <tr class="bg-gray-50">
                            <th v-for="column in tableColumns" :key="column.key"
                                class="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                                @click="updateSort(column.key)">
                                {{ column.label }}
                                <i v-if="sortOption === column.key"
                                    :class="sortDirection === 'asc' ? 'fas fa-sort-up ml-1' : 'fas fa-sort-down ml-1'"></i>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200">
                        <tr v-for="member in filteredTeamMembers" :key="member.id"
                            class="hover:bg-gray-50 transition-colors">
                            <td class="py-3 px-4 whitespace-nowrap">
                                <div class="flex items-center">
                                    <div class="w-8 h-8 rounded-full bg-center bg-cover bg-gray-200 mr-3 flex items-center justify-center"
                                        :style="member.rep?.image?.url ? `background-image: url(${member.rep.image.url})` : ''">
                                        <i v-if="!member.rep?.image?.url" class="fas fa-user text-gray-400 text-xs"></i>
                                    </div>
                                    <div>
                                        <router-link :to="`/member-report/${member.rep?.id}`"
                                            class="font-medium text-blue-600 hover:text-blue-800">
                                            {{ member.rep?.title || 'Unknown Member' }}
                                        </router-link>
                                        <div class="text-gray-500 text-xs">{{ member.role || 'Team Member' }}</div>
                                    </div>
                                </div>
                            </td>
                            <td class="py-3 px-4 whitespace-nowrap text-sm">{{ member.branch?.title || 'Unknown Branch'
                                }}</td>
                            <td class="py-3 px-4 whitespace-nowrap text-sm">{{ formatCurrency(member.revenue) }}</td>
                            <td class="py-3 px-4 whitespace-nowrap text-sm">{{ formatCurrency(member.mtd) }}</td>
                            <td class="py-3 px-4 whitespace-nowrap text-sm">{{ member.contracts || 0 }}</td>
                            <td class="py-3 px-4 whitespace-nowrap text-sm">
                                <div class="flex space-x-2">
                                    <span title="Knock">{{ member.knock || 0 }}K</span>
                                    <span title="Talk">{{ member.talk || 0 }}T</span>
                                    <span title="Walk">{{ member.walk || 0 }}W</span>
                                    <span title="Contingency">{{ member.contingency || 0 }}C</span>
                                </div>
                            </td>
                            <td class="py-3 px-4 whitespace-nowrap text-sm">{{ calculateConversion(member.talk,
                                member.walk) }}%</td>
                            <td class="py-3 px-4 whitespace-nowrap text-sm">{{ calculateConversion(member.contingency,
                                member.contracts) }}%</td>
                        </tr>
                    </tbody>
                </table>

                <!-- Pagination -->
                <div class="mt-4 flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6">
                    <div class="flex-1 flex justify-between sm:hidden">
                        <button @click="currentPage = Math.max(1, currentPage - 1)"
                            :disabled="currentPage === 1"
                            class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            :class="{ 'opacity-50 cursor-not-allowed': currentPage === 1 }">
                            Previous
                        </button>
                        <button @click="currentPage = Math.min(totalPages, currentPage + 1)"
                            :disabled="currentPage === totalPages"
                            class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            :class="{ 'opacity-50 cursor-not-allowed': currentPage === totalPages }">
                            Next
                        </button>
                    </div>
                    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div>
                            <p class="text-sm text-gray-700">
                                Showing
                                <span class="font-medium">{{ ((currentPage - 1) * itemsPerPage) + 1 }}</span>
                                to
                                <span class="font-medium">{{ Math.min(currentPage * itemsPerPage, teamReports.length) }}</span>
                                of
                                <span class="font-medium">{{ teamReports.length }}</span>
                                results
                            </p>
                        </div>
                        <div>
                            <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                                <button @click="currentPage = Math.max(1, currentPage - 1)"
                                    :disabled="currentPage === 1"
                                    class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                    :class="{ 'opacity-50 cursor-not-allowed': currentPage === 1 }">
                                    <span class="sr-only">Previous</span>
                                    <i class="fas fa-chevron-left"></i>
                                </button>
                                <button @click="currentPage = Math.min(totalPages, currentPage + 1)"
                                    :disabled="currentPage === totalPages"
                                    class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                    :class="{ 'opacity-50 cursor-not-allowed': currentPage === totalPages }">
                                    <span class="sr-only">Next</span>
                                    <i class="fas fa-chevron-right"></i>
                                </button>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Modal from '../../components/layout/modal.vue';
import dayjs from 'dayjs';

export default {
    name: 'TeamsReport',
    components: {
        Modal
    },
    props: {
        companyReports: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
        dateRange: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            viewMode: 'table',
            searchQuery: '',
            sortOption: 'revenue',
            sortDirection: 'desc',
            aiOpen: false,
            aiQuery: '',
            aiResponses: [],
            aiRunning: false,
            currentPage: 1,
            itemsPerPage: 100,
            tableColumns: [
                { key: 'title', label: 'Name' },
                { key: 'branch', label: 'Branch' },
                { key: 'revenue', label: 'Revenue' },
                { key: 'mtd', label: 'MTD' },
                { key: 'contracts', label: 'Contracts' },
                { key: 'ktwc', label: 'KTWC' },
                { key: 'conversion', label: 'Conversion' },
                { key: 'closeRate', label: 'Close Rate' }
            ]
        };
    },
    inject: ['axios', 'store'],
    computed: {
        teamReports() {
            if (!this.companyReports?.length) return [];

            // Flatten all rep reports from all company reports
            const allReports = this.companyReports.flatMap(report => report.repReports || []);

            // Group reports by rep ID
            const reportsByRep = {};

            allReports.forEach(report => {
                if (!report.rep?.id) return;

                const repId = report.rep.id;
                if (!reportsByRep[repId]) {
                    reportsByRep[repId] = {
                        id: repId,
                        rep: report.rep,
                        branch: report.branch,
                        role: report.role,
                        revenue: 0,
                        contracts: 0,
                        knock: 0,
                        talk: 0,
                        walk: 0,
                        contingency: 0,
                        mtd: 0
                    };
                }

                // Aggregate metrics
                reportsByRep[repId].revenue += report.revenue || 0;
                reportsByRep[repId].contracts += report.contracts || 0;
                reportsByRep[repId].knock += report.knock || 0;
                reportsByRep[repId].talk += report.talk || 0;
                reportsByRep[repId].walk += report.walk || 0;
                reportsByRep[repId].contingency += report.contingency || 0;

                // Calculate MTD
                const reportDate = dayjs(report.created);
                const now = dayjs();
                if (reportDate.month() === now.month() && reportDate.year() === now.year()) {
                    reportsByRep[repId].mtd += report.revenue || 0;
                }
            });

            return Object.values(reportsByRep);
        },
        filteredTeamMembers() {
            if (!this.teamReports.length) return [];

            // Apply search filter
            let filtered = this.teamReports;
            if (this.searchQuery) {
                const query = this.searchQuery.toLowerCase();
                filtered = filtered.filter(member => {
                    return (
                        member.rep?.title?.toLowerCase().includes(query) ||
                        member.branch?.title?.toLowerCase().includes(query) ||
                        member.role?.toLowerCase().includes(query)
                    );
                });
            }

            // Apply sorting
            filtered = [...filtered].sort((a, b) => {
                let valueA, valueB;

                // Determine the values to compare based on sort option
                switch (this.sortOption) {
                    case 'title':
                        valueA = a.rep?.title?.toLowerCase() || '';
                        valueB = b.rep?.title?.toLowerCase() || '';
                        break;
                    case 'branch':
                        valueA = a.branch?.title?.toLowerCase() || '';
                        valueB = b.branch?.title?.toLowerCase() || '';
                        break;
                    case 'role':
                        valueA = a.role?.toLowerCase() || '';
                        valueB = b.role?.toLowerCase() || '';
                        break;
                    case 'revenue':
                        valueA = a.revenue || 0;
                        valueB = b.revenue || 0;
                        break;
                    case 'mtd':
                        valueA = a.mtd || 0;
                        valueB = b.mtd || 0;
                        break;
                    case 'contracts':
                        valueA = a.contracts || 0;
                        valueB = b.contracts || 0;
                        break;
                    case 'knock':
                        valueA = a.knock || 0;
                        valueB = b.knock || 0;
                        break;
                    case 'conversion':
                        valueA = this.calculateConversion(a.talk, a.walk);
                        valueB = this.calculateConversion(b.talk, b.walk);
                        break;
                    case 'closeRate':
                        valueA = this.calculateConversion(a.contingency, a.contracts);
                        valueB = this.calculateConversion(b.contingency, b.contracts);
                        break;
                    default:
                        valueA = a.revenue || 0;
                        valueB = b.revenue || 0;
                }

                // Apply sort direction
                if (['title', 'branch', 'role'].includes(this.sortOption)) {
                    // String comparison
                    return this.sortDirection === 'asc'
                        ? valueA.localeCompare(valueB)
                        : valueB.localeCompare(valueA);
                } else {
                    // Numeric comparison
                    return this.sortDirection === 'asc'
                        ? valueA - valueB
                        : valueB - valueA;
                }
            });

            // Get the current page of items
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return filtered.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.teamReports.length / this.itemsPerPage);
        }
    },
    methods: {
        calculateConversion(value1, value2) {
            if (!value1 || !value2) return 0;
            return ((value2 / value1) * 100).toFixed(1);
        },

        formatCurrency(value) {
            if (!value) return '$0';
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }).format(value);
        },

        updateSort(column) {
            if (this.sortOption === column) {
                // Toggle direction if clicking the same column
                this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
            } else {
                // Set new column and default to desc for most metrics
                this.sortOption = column;
                this.sortDirection = ['title', 'branch', 'role'].includes(column) ? 'asc' : 'desc';
            }
        },

        async queryAi() {
            if (!this.aiQuery || this.aiRunning) return;

            this.aiRunning = true;
            try {
                const response = await this.axios.post(`${this.store.api}/controllers/ai/analyze`, {
                    query: this.aiQuery,
                    data: this.teamReports,
                    context: {
                        dateRange: this.dateRange,
                        type: 'team'
                    }
                });

                if (response?.data?.content) {
                    this.aiResponses.unshift(response.data.content);
                    this.aiQuery = '';
                }
            } catch (error) {
                console.error('Error querying AI:', error);
                this.aiResponses.unshift('Sorry, I was unable to process your query. Please try again.');
            } finally {
                this.aiRunning = false;
            }
        },

        exportData() {
            if (!this.teamReports?.length) return;

            // Prepare CSV data
            const headers = [
                'Name',
                'Role',
                'Branch',
                'Revenue',
                'MTD',
                'Contracts',
                'Knock',
                'Talk',
                'Walk',
                'Contingency',
                'Conversion Rate',
                'Close Rate'
            ];

            const rows = this.filteredTeamMembers.map(member => [
                member.rep?.title || 'Unknown Member',
                member.role || 'Team Member',
                member.branch?.title || 'Unknown Branch',
                member.revenue || 0,
                member.mtd || 0,
                member.contracts || 0,
                member.knock || 0,
                member.talk || 0,
                member.walk || 0,
                member.contingency || 0,
                this.calculateConversion(member.talk, member.walk),
                this.calculateConversion(member.contingency, member.contracts)
            ]);

            // Create CSV content
            let csvContent = headers.join(',') + '\n';
            rows.forEach(row => {
                csvContent += row.join(',') + '\n';
            });

            // Create download link
            const encodedUri = encodeURI('data:text/csv;charset=utf-8,' + csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', `team-performance-${dayjs().format('YYYY-MM-DD')}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};
</script>

<style scoped>
.teams-report {
    @apply w-full;
}
</style>
