String.prototype.toTitleCase = function (originCase) {
    let str = `${this.toString()}`;

    switch (originCase) {
        case 'camel':
            str = str.replace(/([A-Z])/g, ' $1');
            break;
        case 'snake':
            str = str.replace(/_/g, ' ');
            break;
        case 'kebab':
            str = str.replace(/-/g, ' ');
            break;
        case 'pascal':
            str = str.replace(/([A-Z])/g, ' $1').trim();
            break;
    }

    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

import { timezone, apiDomain, port, useDevAPI } from '../../config/config/settings.json'
import collections from '../../config/config/.collections.json'

import axios from 'axios'

import { computed, createApp, defineAsyncComponent, reactive, ref } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import VueClipboard from 'vue3-clipboard'
import Vue3TouchEvents from 'vue3-touch-events'
import App from './App.vue'
import './index.css'

/*
    If these are dynamicly imported I end up with a blank
    screen while the route is waiting for the component
    to download... not sure how to fix.
*/

import Home from './components/pages/home.vue'
import Entry from './helpers/Entry.vue'
import Entries from './helpers/Entries.vue'
import MemberReport from './pages/member-report.vue'
import MyTeam from './pages/my-team.vue'
import BranchStats from './pages/branch-stats.vue'
import ManageTeam from './pages/manage-team.vue'
import TeamStats from './pages/team-stats.vue'
import Account from './components/pages/account.vue'
import BranchReports from './pages/branch-reports.vue'
import CompanyReports from './pages/company-reports.vue'
// import Estimate from "./pages/estimate.vue";
import Opportunities from "./pages/opportunities.vue";
import ExportOpportunities from "./pages/export-opportunities.vue";
import Reporting from "./pages/reporting.vue";
import FAQ from './pages/admin-faq.vue'
import HeatMap from './pages/heat-map.vue'
import fourOhFour from './components/pages/404.vue'

// For android phones (added to manifest)
// screen.orientation.lock('portrait-primary')

const routes = [
    { path: '/', component: Home, meta: { keyed: false } },
    { path: '/dashboard', component: Home, meta: { keyed: false } },
    { path: '/dashboard/:tab', component: Home, meta: { keyed: false } },
    { path: '/dashboard/members/:id', component: Home, meta: { keyed: false } },
    { path: '/account', component: Account },
    { path: '/faq', component: FAQ },
    { path: '/heat-map', component: HeatMap },
    { path: '/members/:id/report', component: MemberReport },
    { path: '/branchReports/view', component: BranchReports },
    { path: '/branchReports/view/:id', component: BranchReports },
    { path: '/companyReports/view', component: CompanyReports },
    { path: '/reporting', component: Reporting },

    { path: '/my-branches', component: ManageTeam },
    { path: '/my-team', component: MyTeam },
    { path: '/my-team/:id', component: MyTeam },

    { path: '/manage-team', component: ManageTeam },
    { path: '/manage-team/:id', component: MyTeam },
    { path: '/team-stats', component: BranchStats },
    { path: '/team-stats/:branchId', component: TeamStats },
    { path: '/branch-stats', component: BranchStats },


    { path: '/opportunities', component: Opportunities },
    { path: '/opportunities/export', component: ExportOpportunities },
    // { path: '/opportunities/:id/estimate', component: Estimate },

    // { path: '/branch-stats/:id', component: MyTeam },

    { path: '/team-stats/:branchId', component: TeamStats },
    { name: '404', path: "/:pathMatch(.*)*", component: fourOhFour },

]

// .filter(c => c.name != 'branchReports')
collections
    .forEach((c) => {
        if (c.name != 'opportunities') routes.push({ path: `/${c.name}`, component: Entries })
        routes.push({ path: `/${c.name}/create`, component: Entry })
        routes.push({ path: `/${c.name}/:id`, component: Entry })
    })

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        let toPath = to.matched?.[0]?.path

        let sameComponent =
            to.matched?.[0]?.components?.default ==
            from.matched?.[0]?.components?.default
        let sameComponentExceptions = ['/resources/:id', /account\/*/g]
        let exceptionExists = sameComponentExceptions.some(
            (e) => !!toPath.match(e)?.length
        )

        if (to.hash) {
            return { el: to.hash, behavior: 'smooth' }
        }
        if (savedPosition) return savedPosition
        if (sameComponent && !exceptionExists) return null

        return { top: 0 }
    },
    routes,
})

const app = createApp(App)

app.use(router)
app.use(Vue3TouchEvents)
app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
})

import VueLazyload from 'vue-lazyload'
app.use(VueLazyload)

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
app.use(FloatingVue)

/*

Global Properties

*/

import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
dayjs.extend(tz)

dayjs.tz.setDefault(timezone)

app.provide('dayjs', dayjs)

import breakpoints from './helpers/breakpoints'
import darkMode from './helpers/darkMode'
import formatPhone from './helpers/formatPhone'
import { initUser } from './helpers/user'

const isDev = import.meta.env.DEV

let api = useDevAPI ? `http://localhost:${port}` : apiDomain
if (!isDev) api = apiDomain

app.provide('breakpoints', breakpoints().breakpoints)
app.provide('darkMode', darkMode().darkMode)

const user = initUser()
const store = reactive({
    opportunities: [],
    showMenu: false,
    user,
    api,

    isDev,

    login: {},

    touchDevice:
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0,
})

let Authorization = window.localStorage.getItem('auth')
axios.defaults.headers.common['Authorization'] = Authorization

app.provide('store', store)
app.provide('axios', axios)

// app.provide('settings', settings)
app.provide('collections', collections)

app.provide('mode', import.meta.env.MODE)
app.provide('formatPhone', formatPhone)

/*

Global Components

*/

import Mango from './helpers/Mango.vue'
import Spinner from './components/layout/spinner.vue'
import Modal from './components/layout/modal.vue'

app.component('Mango', Mango)
app.component('Spinner', Spinner)
app.component('Modal', Modal)
// app.component('resource', defineAsyncComponent(Layouter.vue')))

/*

Route Guard

*/

router.beforeEach((to, from, next) => {
    // Log in google analytics...
    if (window.ga) window.ga('send', 'pageview', to.fullPath)

    let protectedRoute = to.meta?.protected
    let roles = store.user?.roles
    let loggedIn = !!store.user?.id
    let admin = roles?.includes('admin')
    let local = window.location.href.includes('localhost')

    if (!protectedRoute || admin || local) return next()

    let approved = Array.isArray(protectedRoute)
        ? protectedRoute.some((role) => roles?.includes(role))
        : loggedIn

    if (approved) return next()
    else {
        console.log('Protected:', protectedRoute, roles)
        store.login = { next: to.path }

        if (!to.meta?.fallback) return next('')

        // Get params from path and add them to the fallback path (/event/:id)
        let fallback = to.meta.fallback
            ?.split('/')
            ?.map((p) => {
                if (p.includes(':')) {
                    let param = p.replaceAll(':', '')
                    return to.params[param] || ''
                } else {
                    return p
                }
            })
            ?.join('/')

        return next(fallback || to.meta.fallback)
    }
})

/*

SENTRY

*/

// import * as Sentry from "@sentry/vue";

// Sentry.init({
//     app,
//     dsn: "https://5e85baec172444835c6dfdf23cb6d7af@o4506316278595584.ingest.sentry.io/4506328619089920",
//     integrations: [
//         new Sentry.BrowserTracing({
//             // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//             tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//             routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         }),
//         new Sentry.Replay({
//             networkDetailAllowUrls: ['https://api.bestchoiceroofing.com/members/*']
//         }),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });


app.mount('#app')
